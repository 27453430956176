<template>
  <div class="content-list" v-if="isPageReady">
    <Action-panel :isReady="isPageReady">
      <div>
        <Button-base
          :type="2"
          :text="translations.labels.button_add_content"
          :hoverInfo="translations.labels.button_add_content_hover"
          :onClick="newItem"
        />
        <Button-base
          :type="2"
          :text="translations.labels.button_order"
          :hoverInfo="translations.labels.button_order_hover"
          :onClick="order"
        />
      </div>
    </Action-panel>
    <v-card class="ma-6">
      <v-tabs v-model="selectedTab" flat color="primary">
        <v-tab
          v-for="tab in languages"
          :key="tab.id"
          :href="`#tab-${tab.id}`"
          centered
          icons-and-text
        >
          {{ tab.description }}
        </v-tab>
        <v-tabs-items v-model="selectedTab">
          <v-tab-item
            v-for="tab in languages"
            :key="tab.id"
            :value="`tab-${tab.id}`"
          >
            <v-card :isReady="isPageReady">
              <TableList
                :is-ready="model.length > 0"
                :headers="headers"
                :items="model.filter((l) => l.idLanguage === tab.id)[0].items"
                :items-per-page="-1"
                sortable
                hideFooter
                @sorted="saveOrder"
                item-key="id"
                :searchVisible="false"
              />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>
    <ConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import ConfirmDialog from "@/components/dom/ConfirmDialog";
import Vue from "vue";
import Component from "vue-class-component";
import { toastService } from "@/services/toastService.js";
import Container from "@/components/dom/Container";
import ActionPanel from "@/components/panel/ActionPanel";
import InnerPanel from "@/components/panel/InnerPanel";
import TablePanel from "@/components/panel/TablePanel";
import ButtonBase from "@/components/dom/ButtonBase";
import SvgIcon from "@/components/dom/SvgIcon";
import Round from "@/components/dom/Round";
import TableList from "@/components/table/TableList";

@Component({
  inject: {
    eventService: "eventService",
    contentService: "contentService",
  },
  components: {
    ActionPanel,
    InnerPanel,
    TablePanel,
    ConfirmDialog,
    ButtonBase,
    SvgIcon,
    Round,
    Container,
    TableList
  }
})
export default class ContentList extends Vue {
  isPageReady = false;
  model = [];
  languages = [];
  selectedTab = null;
  event = null;

  async saveOrderCall(idLanguage) {
    const newOrders = [];
    this.model
      .filter((e) => e.idLanguage === idLanguage)[0]
      .items.forEach((item, index) => {
        newOrders.push({
          id: item.id,
          newOrder: index + 1,
        });
        item.order = index + 1;
      });
    try {
      await this.contentService.updateOrder(newOrders);
    } catch (error) {
      toastService.error(this.translations.errors.somethingWrong);
    }
  }

  async saveOrder(event) {
    const idLanguage = this.selectedTab.toString().substr(4);
    const movedItem = this.model
      .filter((e) => e.idLanguage === idLanguage)[0]
      .items.splice(event.oldIndex, 1)[0];
    this.model
      .filter((e) => e.idLanguage === idLanguage)[0]
      .items.splice(event.newIndex, 0, movedItem);
    await this.saveOrderCall(idLanguage);
  }

  newItem() {
    this.$router.push(
      `/content/${this.$route.params.id}/${this.$route.params.type}/new`
    );
  }

  categoriesSubdivision(items) {
    let insertedCategories = [];
    let subdividedArray = [];

    items.forEach(item => {
      if (!(insertedCategories.includes(item.category))) {
        insertedCategories.push(item.category);
        let newCategory = {
          "categoryTitle" : item.category,
          "items" : []
        };
        subdividedArray.push(newCategory);
      }
      const index = (element) => element.categoryTitle == item.category;
      const arrayIndexToPushIn = subdividedArray.findIndex(index);
      subdividedArray[arrayIndexToPushIn].items.push(item);
    });
    return subdividedArray;
  }

  order() {
    const idLanguage = this.selectedTab.toString().substr(4);
    const thisLang = this.model.filter((e) => e.idLanguage === idLanguage);
    if (thisLang.length == 0) {
      return;
    }
    let categorizedItems = this.categoriesSubdivision(thisLang[0].items);
    let items = [];
    categorizedItems.forEach(c => {
      items = [
        ...items,
        ...c.items
      ];
    });

    thisLang[0].items = items;

    this.saveOrderCall(idLanguage);
  }

  async del(id) {
    if (
      await this.$refs.confirm.open(
        this.translations.confirmDialog.title,
        this.translations.confirmDialog.message
      )
    ) {
      try {
        await this.contentService.remove(id);
        this.loadData();
      } catch (error) {
        toastService.error(this.translations.errors.somethingWrong);
      }
    }
  }
  async loadData() {
    this.isPageReady = false;
    this.model = [];
    const contents = await this.contentService.list(
      this.$route.params.id,
      this.$route.params.type
    );
    if (this.languages != undefined)
      this.languages.forEach((lang) => {
        this.model.push({
          idLanguage: lang.id,
          items: contents
            .filter((e) => e.idLanguage === lang.id)
            .map((c) => {
              return {
                id: c.id,
                name: c.name,
                category: c.categoryCode,
                uri: c.uri,
                order: c.order,
              };
            }),
        });
      });
    this.isPageReady = true;
  }
  async loadLanguages() {
    this.languages = await this.eventService.relatedLanguages(
      this.$route.params.id
    );
  }
  async init() {
    try {
      await this.loadLanguages();
      await this.loadData();
    } catch (error) {
      toastService.error(this.translations.errors.somethingWrong);
    }
  }
  get translations() {
    return this.$store.state.translationsStore.currentLang;
  }
  get toolbarTitle() {
    return this.translations.labels.form_localizations;
  }
  get headers() {
    return [
      {
        text: this.translations.labels.content_table_name,
        value: "name",
        sortable: false,
      },
      {
        text: this.translations.labels.content_table_category,
        value: "category",
        sortable: false,
      },
      {
        text: this.translations.labels.content_table_uri,
        value: "uri",
        sortable: false,
        align: this.$route.params.type !== "HtmlContent" ? null : " d-none",
      },
      {
        text: this.translations.labels.content_table_order,
        value: "order",
        sortable: false,
      },
      {
        text: this.translations.labels.table_delete,
        value: "delete",
        sortable: false,
        type: "action",
        icon: "mdi-delete",
        onAction: (item) => {
          this.del(item.id);
        },
      },
      {
        text: this.translations.labels.table_edit,
        value: "edit",
        sortable: false,
        type: "link",
        formatValue: (item) => {
          return `/content/${this.$route.params.id}/${this.$route.params.type}/edit/${item.id}`;
        },
        icon: "mdi-pencil",
        buttonType: 2,
      },
    ];
  }

  mounted() {
    this.init();
  }
}
</script>
<style lang="scss" scoped>
.event-list {
  .btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 30px 0 30px;
    margin: 15px 30px 0 30px;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
</style>
